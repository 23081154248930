<template>
  <div class="applications" :class="{skeleton: !state.loaded}">
    <div class="header">
      <div class="title">
        <span>나의 신청서</span>
      </div>
      <div class="desc">
        <span>상세 내용을 보시려면 해당 항목을 선택해주세요.</span>
      </div>
    </div>
    <div class="items">
      <div class="item" v-for="(a, idx) in state.applications" :key="idx" :class="{finished: a.finished && !a.canceled, canceled: a.canceled || (a.status === 'pass' || a.status === 'fail' || a.status === 'done')}">
        <Anchor :href="a.canceled || (a.status === 'pass' || a.status === 'fail'|| a.status === 'done') ? '' : `/archives/${a.archiveName}/applications/${a.id}`" class="d-flex justify-content-between align-items-center">
          <div class="content">
            <div class="date color-secondary">
              <span>최근 입력: </span>
              <span>{{ a.saveDate }}</span>
            </div>
            <div class="title d-flex">
              <span class="badge" :class="a.status">{{ stateText(a.status, a.finished) }}</span>
              <span class="text">{{ a.archiveTitle }}</span>
            </div>
          </div>
          <button class="btn btn-light font-sm" @click.prevent="cancel(a.archiveId, a.id)" v-if="!a.canceled && !(a.status === 'pass' || a.status === 'fail' || a.status === 'done')">취소</button>
        </Anchor>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/scripts/mixin";
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Anchor from "@/components/Anchor";

function Component(initialize) {
  this.name = "pageArchiveApplications";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      load();
    });

    const state = reactive({
      applications: [],
      loaded: false,
    });

    const load = () => {
      state.loaded = false;
      for (let i = 0; i < 3; i += 1) {
        state.applications.push({
          archiveTitle: "Please wait a moment",
          saveDate: "0000*00-00 00:00:00",
          finished: false
        });
      }
      http.get("/api/archive/applications").then(({data}) => {
        state.applications = data.body;
        state.loaded = true;
      });
    };

    const cancel = (archiveId, applicationId) => {
      store.commit("confirm", {
        message: "해당 신청을 취소하시겠습니까?",
        allow() {
          http.put(`/api/archives/${archiveId}/applications/${applicationId}/cancel`).then(async () => {
            load();
            await store.commit("setSwingMessage", "신청을 취소하였습니다.");
          });
        }
      });
    };

    const stateText = (state, finished) => {
      if (!finished && state !== "denied") {
        return "작성 중";
      } else {
        switch (state) {
          case "unproven":
            return "접수 완료";
          case "judge":
            return "심사 중";
          case "denied":
            return "보완";
          case "pass":
            return "통과";
          case "fail":
            return "반려";
          case "done":
            return "처리 완료";
          default:
            return "";
        }
      }
    };

    return {component, state, cancel, stateText};
  }
});
</script>

<style lang="scss" scoped>
.applications {
  padding: $px50 $px15;

  .header {
    padding: 0 $px20;

    .title {
      font-size: $px19;
      font-weight: 900;
    }

    .desc {
      color: #666;
      font-size: $px14;
      padding-top: $px7;
    }
  }

  > .items {
    padding-top: $px50;

    > .item {
      border-radius: $px4;
      border: $px1 solid #f7f7f7;

      > a {
        display: block;
        font-size: $px14;
        text-decoration: none;
        padding: $px20;

        .content {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          .date {
            font-size: $px11;
          }

          .title {
            padding-top: $px5;
            align-items: center;

            .badge {
              color: #eee;
              background-color: #eee;
              display: inline-block;
              font-size: $px10;
              padding: $px4;
              margin-right: $px6;
              background: $colorPurple;

              &.pass {
                background: $colorSuccess;
              }

              &.denied {
                background: $colorWarning;
              }

              &.fail {
                background: $colorDanger;
              }

              &.done {
                background: $colorPointActive;
              }
            }

            .text {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .btn {
          flex-shrink: 0;
          margin-left: $px20;
        }
      }

      //&.finished > a .content .title .badge {
      //  background-color: $colorPurple;
      //  color: #fff;
      //}

      &.canceled > a {
        .content {
          opacity: 0.5;
        }
      }

      &:not(.canceled) > a:hover {
        background: #f7f7f7;
      }

      &:not(:last-child) {
        margin-bottom: $px12;
      }
    }
  }

  &.skeleton {
    > .header span {
      @include skeleton;
    }

    .items .item {
      a {
        cursor: default;

        .content span, .btn {
          @include skeleton;
        }
      }

      &:hover {
        background: initial;
      }
    }
  }
}
</style>